@import "variable";

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1390px;
  }
}
img {
  max-width: 100%;
}
.pointer {
  cursor: pointer;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.truncate-line {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.truncate-line-2 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.header {
  background: #1a1a1a;
  padding: 17px 10px;
  @media (max-width: 991px) {
    padding: 20px 10px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-mobile {
    display: none;
    @media (max-width: 991px) {
      display: block;
      cursor: pointer;
    }
  }
  .menu {
    @media (max-width: 991px) {
      display: none;
      &.is-mobile {
        display: block;
        position: fixed;
        z-index: 2;
        width: 100%;
        padding: 20px 0;
        top: 72px;
        right: 0;
        background: #1a1a1a;
        border-top: 1px solid #585656;
      }
    }
    .menu-list {
      margin: 0;
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .menu-item {
        padding: 28px;
        @media (max-width: 991px) {
          margin: 0 0 10px;
          padding: 10px 25px;
        }
        &:hover {
          .menu-mega {
            display: block;
          }
        }
        .menu-link {
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 21.09px;
          text-align: left;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            color: $colorPrimary;
          }
          &.active {
            color: $colorPrimary;
          }
        }
        .menu-mega {
          display: none;
          position: absolute;
          font-family: "Poppins", sans-serif;
          z-index: 2;
          background: #fff;
          width: 1220px;
          height: 490px;
          top: 80px;
          border-radius: 7px;
          left: 50%;
          transform: translateX(-50%);
          @media (max-width: 991px) {
            width: 100%;
            overflow-y: scroll;
            border-bottom: 1px solid #ede1e1;
            box-shadow: 0px 0px 10px #ccc;
            border-radius: 0;
            left: 0;
            transform: translateX(0);
          }
          .content {
            padding: 60px 22px;
            align-items: flex-start;
            @media (max-width: 991px) {
              flex-direction: column;
              padding: 40px 0 0;
            }
            .block {
              position: relative;
              height: 100%;
              width: 33.33%;
              padding: 0 30px;
              @media (max-width: 991px) {
                width: 100%;
                margin: 0 0 20px;
              }
              &::after {
                position: absolute;
                content: "";
                top: 0;
                right: 0;
                height: 400px;
                width: 1px;
                background-color: rgb(201, 196, 196);
                @media (max-width: 991px) {
                 display: none;
                }
              }
              &:last-child {
                &::after {
                  display: none;
                }
              }
              .name {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                text-align: left;
                color: #313131;
                margin: 0 0 16px;
              }
              .list {
                .item {
                  cursor: pointer;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 26px;
                  text-align: left;
                  color: #121e288f;
                  margin: 0 0 16px;
                  text-decoration: none;
                  &:hover {
                    color: #de5d2f8f;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.footer {
  padding: 75px 0 0;
  background: #1a1a1a;
  font-family: "Poppins", sans-serif;
  .head {
    font-size: 15.34px;
    font-weight: 600;
    line-height: 26.08px;
    letter-spacing: 0.9px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff8f;
  }
  .logo {
    margin: 0 0 30px;
    @media (max-width: 639px) {
      text-align: center;
    }
  }
  .footer-link {
    margin: 0 0 30px;
    .list {
      margin: 22px 0 0;
      .item {
        display: flex;
        align-items: center;
        margin: 0 0 9px;
        a {
          color: #fff;
          &:hover {
            color: $colorPrimary;
          }
        }
        .icon {
          padding: 0 22px 0 0;
        }
        .text {
          font-size: 16px;
          font-weight: 600;
          line-height: 28.8px;
          text-align: left;
          color: #fff;
        }
      }
    }
  }
  .follow {
    .group-btn {
      margin: 11px 0 0;
      input {
        margin: 0 3px 10px 0;
        width: 327px;
        height: 42px;
        padding: 6.58px 13.15px 5.48px 13.15px;
        gap: 170.96px;
        border-radius: 6.58px;
        border: 1.1px solid #ffffff1f;
        background: transparent;
        box-shadow: 0 0 0;
        outline: 0;
        color: #fff;
        @media (max-width: 991px) {
          width: 70%;
        }
      }
      button {
        padding: 8.77px 26.3px 8.77px 26.3px;
        gap: 0px;
        border-radius: 6.58px;
        opacity: 0px;
        background: #de5d2f;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 26.3px;
        text-align: left;
        outline: 0;
        border: 0;
      }
    }
  }
  .copyright {
    margin: 45px 0 0;
    padding: 30px 0;
    border-top: 1px solid #ffffff1f;
    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: center;
      color: #ffffff8f;
    }
  }
}
