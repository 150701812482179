@import "variable";

.banner {
  padding: 197px 0 445px;
  background: url("../images//banner.png") no-repeat left center;
  color: hsl(0, 0%, 100%);
  @media (max-width: 991px) {
    padding: 150px 0 200px;
  }
  @media (max-width: 639px) {
    padding: 100px 0 150px;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 50.4px;
    text-align: justify;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  .des {
    margin: 24px 0 0;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
  }
}

.about-us {
  padding: 155px 0;
  background: #01060c;
  @media (max-width: 639px) {
    padding: 100px 0;
  }
  .inner-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .inner {
      max-width: 540px;
      text-align: justify;
      .title {
        font-family: "Roboto", sans-serif;
        font-size: 48px;
        font-weight: 600;
        line-height: 44.39px;
        text-align: left;
        color: $colorPrimary;
        @media (max-width: 991px) {
          margin: 30px 0 0;
        }
      }
      .des {
        margin: 20px 0 0;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justify;
        color: #fff;
      }
    }
  }
}

.product-range {
  padding: 125px 0 100px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  .title {
    padding: 0 0 30px;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #121e28;
    .focus {
      color: $colorPrimary;
    }
  }
  .sub-title {
    margin: 30px 0 62px;
    font-size: 32px;
    font-weight: 600;
    line-height: 34.24px;
    text-align: center;
    color: #121e28;
  }
  .inner {
    margin: 0 0 30px;
    .img {
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .name {
      margin: 18px 0 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 34.24px;
      text-align: left;
      color: #121e28;
    }
    .des {
      margin: 6px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: justify;
      color: #121e288f;
    }
    .read-more {
      cursor: pointer;
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
      line-height: 34.24px;
      text-align: left;
      color: $colorPrimary;
    }
  }
}

.why-choose {
  background: url("../images/why-not-bg.png") no-repeat top center;
  background-color: #fff;
  padding: 88px 0 230px;
  font-family: "Poppins", sans-serif;
  @media (max-width: 991px) {
    background-image: none;
  }
  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }
  .title {
    display: inline-block;
    width: 100%;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #121e28;
    .focus {
      color: $colorPrimary;
    }
  }
  .sub-title {
    margin: 10px 0 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: #121e28;
  }
  .inner {
    max-width: 396px;
    margin: 0 auto 30px;
    .img {
      text-align: center;
    }
    .name {
      margin: 18px 0 6px;
      font-size: 24px;
      font-weight: 600;
      line-height: 34.24px;
      text-align: center;
      color: #121e28;
    }
    .des {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: justify;
      color: #121e288f;
    }
  }
}

.contact-us {
  background: url("../images/contact-bg.png") no-repeat top center;
  background-color: #121e52;
  font-family: "Poppins", sans-serif;
  padding: 130px 0 160px;
  .content {
    background: #fff;
    box-shadow: 0px 26.67px 66.67px 0px #12112714;
    border-radius: 16px;
    padding: 64px 145px 100px;
    @media (max-width: 991px) {
      padding: 50px;
    }
    @media (max-width: 639px) {
      padding: 20px;
    }
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 40.8px;
      letter-spacing: 1.07px;
      text-align: left;
      color: $colorPrimary;
    }
    .des {
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
      text-align: left;
      color: #121e28;
      @media (max-width: 639px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

.news-section {
  padding: 170px 0 200px;
  font-family: "Poppins", sans-serif;
  @media (max-width: 991px) {
    padding: 70px 0 100px;
  }
  .title {
    font-size: 48px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: center;
    color: $colorPrimary;
    margin: 0 0 40px;
  }
  .inner {
    margin: 0 0 40px;
    @media (max-width: 991px) {
      width: 100%;
      flex-direction: column;
      .img {
        text-align: center;
        margin: 0 0 20px;
      }
    }
    .img {
      max-width: 502px;
    }
    .name {
      margin: 0 0 8px;
      cursor: pointer;
      font-size: 24px;
      font-weight: 600;
      line-height: 31.17px;
      text-align: left;
      color: #121e28;
      &:hover {
        color: $colorPrimary;
      }
    }
    .des {
      font-size: 15px;
      font-weight: 400;
      line-height: 32px;
      text-align: justify;
      color: #121e288f;
    }
  }
  .col-left {
    .inner {
      .name {
        margin: 20px 0 0;
        max-width: 502px;
        text-align: justify;
      }
    }
  }
  .col-right {
    padding: 0;
    @media (max-width: 639px) {
      padding-right: 15px;
      padding-left: 15px;
    }
    .inner {
      display: flex;
      align-items: center;
      .img {
        width: 38%;
        height: 157px;
        @media (max-width: 991px) {
          width: 100%;
        }
        img {
          height: 100%;
        }
      }
      .wrap {
        width: 62%;
        padding: 0 0 0 15px;
        @media (max-width: 991px) {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

//******** page news
.page-news {
  font-family: "Poppins", sans-serif;
  padding: 66px 0 150px;
  .main-img {
    margin: 0 0 108px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 16px;
    overflow: hidden;
  }
  .head {
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
    color: #121e28;
  }
  .date {
    margin: 10px 0 47px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #121e28;
    opacity: 0.6;
  }
  .des {
    .text {
      margin: 0 0 15px;
      text-align: justify;
    }
  }
  .news-related {
    margin: 140px 0 0;
    padding: 0;
  }
}

//******** page product
.page-product {
  font-family: "Poppins", sans-serif;
  color: #121e52;
  .main-img {
    margin: 0 0 168px;
    @media (max-width: 639px) {
      margin: 0 0 100px;
    }
  }
  .col-12 {
    .img {
      margin: 0 0 50px;
    }
    .des {
      margin: 20px 0 0;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      max-width: 700px;
      .text {
        margin: 0 0 10px;
      }
    }
  }
  .head {
    font-size: 48px;
    font-weight: 700;
    line-height: 67.2px;
    text-align: left;
  }

  .function {
    padding: 0 0 215px;
    @media (max-width: 639px) {
      padding: 0 0 100px;
    }
  }
}
